/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Layout from "../components/layout";
import { Link } from "gatsby";
import Container from "../components/container";
import { graphql } from "gatsby";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import ProductTeaserSmall from "../components/entities/product/productTeaserSmall";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

const Resources = (props) => {
  console.log(props);
  const bgImage = convertToBgImage(
    props.data.heroImage.childImageSharp.gatsbyImageData
  );

  return (
    <Layout type="resource-product-index-outer default-outer">
      <SEO title="Resources" lang="en" description=""></SEO>
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Container>
          <div
            sx={{
              position: "relative",
              zIndex: "2",
              padding: "180px 0px 100px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 sx={{ color: "white" }}>Resources</h1>
            <div
              sx={{
                a: {
                  color: "white",
                },
              }}
            >
              <Link to={"/"}>Home</Link>
            </div>
          </div>
        </Container>
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, #0b5d57 0%, #000000 98%, #000000 100%)",
          }}
        ></div>
      </BackgroundImage>
      <Container className="resources-product-index-container">
        <ul
          sx={{
            padding: "40px 0px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.data.product.nodes.map((entity, index) => (
            <li
              key={index}
              sx={{
                marginBottom: "40px",
                listStyle: "none",
                display: "flex",
                padding: "0px 10px",
                width: [
                  "100%",
                  "calc(100% / 2 - 20px)",
                  "calc(100% / 3 - 20px)",
                ],
                marginBbottom: "30px",
                display: "flex",
              }}
            >
              <ProductTeaserSmall entity={entity}></ProductTeaserSmall>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  );
};

export default Resources;

export const resourceIndexQuery = graphql`
  query resourceProductQuery {
    blueArrowRight: file(name: { eq: "Right-Blue-Caret" }) {
      publicURL
    }
    blueArrowLeft: file(name: { eq: "Left-Blue-Caret" }) {
      publicURL
    }
    heroImage: file(name: { eq: "freedom" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    product: allSanityProduct {
      nodes {
        _rawIntroText(resolveReferences: { maxDepth: 10 })
        headline
        slug {
          current
        }
        main_image {
          asset {
            gatsbyImageData(height: 200, width: 400)
          }
        }
      }
    }
  }
`;
